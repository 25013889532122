import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IoCloudDownloadOutline } from 'react-icons/io5';
import Select from 'react-select';
import styles from './ResultsMainMenu.module.css';
import ImportCheck from './ImportCheck';

const raceOptions = [{ option: 'Malibu Rum Race' }, { option: 'Jameson Race' },{ option: 'Ladies Malibu Rum Race' },{ option: 'Moonlight Race' },{ option: 'Mother\'s Arms Challenge' },{ option: 'Trans-Monroe Regatta' }];

const Icon = styled(IoCloudDownloadOutline)`
  transform: scale(1.2);
  margin: 0;
  vertical-align: middle;
  // margin-right: 2.4rem;
  color: #e7f5ff;
  padding: auto;
  margin-right: 1rem;
`;

const ResultsMainMenu = (props) => {
  const [eventType, setEventType] = useState();
  const [eventDate, setEventDate] = useState();
  const [importData, setImportData] = useState();

  const customStyles = {
    control: (base, state) => ({
      ...base,
      width: '20rem',
      fontSize: '1.6rem',
    }),
  };

  const pdfHandler = () => {
    window.open(
      `/api/pdf_results?event_type=${encodeURIComponent(
        eventType
      )}&event_date=${encodeURIComponent(eventDate)}`
    );
  };


  const updateLatestHandler = () => {
    window.open(
      `/api/pdf_results?event_type=${encodeURIComponent(
        eventType
      )}&event_date=${encodeURIComponent(eventDate)}&publish=y`
    );
  };

  // const fetchData = async () => {
  //   const response = await fetch('/api/add_scratch_time', {
  //     method: 'POST',
  //     body: JSON.stringify({
  //       elapsed_time: apiData.elapsed_time,
  //       racer_id: apiData.racer_id,
  //     }),
  //     headers: { 'Content-Type': 'application/json' },

  //     credentials: 'include',
  //   }).then((res) => {
  //     if (res.ok) {
  //       window.alert('Time accepted');
  //     } else {
  //       return;
  //     }
  //   });
  // };
  // fetchData();

  // if (window.confirm('Are you sure you want to import Self-Entries?'))

  let results = '';

  const importHandler = () => {
    // if (window.confirm(`Are you sure you want to import Self-Entries?`)) {
    //   const fetchData = async () => {
    //     const response = await fetch('/api/import_scratch_times', {
    //       method: 'POST',
    //       body: JSON.stringify({}),
    //     });

    //     results = await response.json();
    //     props.handleImportData(results);
    //   };
    //   fetchData();
    // }

    props.importModalHandler();
  };

  const handleRaceSelection = (e) => {
    setEventType(e.option);
  };

  const handleDateSelection = (e) => {
    setEventDate(e.target.value);
  };

  return (
    <section className={styles.menu}>
      <form className={styles.formitems}>
        <label className={styles.entryBox}>
          Event Type
          <Select
            styles={customStyles}
            options={raceOptions}
            onChange={handleRaceSelection}
            type="text"
            name="group"
            getOptionLabel={(option) => option.option}
            getOptionValue={(option) => option.option}
          />
        </label>
        <label className={styles.items}>
          Event Date
          <input
            className={styles.inputbox}
            type="date"
            name="eventDate"
            onChange={handleDateSelection}
          />
        </label>
      </form>

      <div className={styles.btndiv}>
        <button className={styles.btn} onClick={importHandler}>
          <Icon />
          <span>Import</span>
        </button>
        <button className={styles.btn} onClick={pdfHandler}>
          Create PDF
        </button>
        <button className={styles.btn} onClick={updateLatestHandler}>
          Publish PDF
        </button>
      </div>
    </section>
  );
};

export default ResultsMainMenu;
